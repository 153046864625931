.storyCard {
  box-shadow: 10px 10px 10px grey;
  // min-height: 75vh;
  width: 90vw;
  background-color: white;
  margin: 3vh auto;

  h2 {
    font-size: 2.2em;
    padding: 20px;
    text-align: center;
  }

  p {
    font-size: 1.2em;
    padding: 0 20px 20px 20px;
  }
}
