nav {
  background-color: rgba(0, 0, 0, 0.404);
  position: absolute;
  top: 10vh;
  left: 0;
  height: 90vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 1;

  * {
    display: block;
    background-color: #00000096;
    color: white;
    text-decoration: none;
    border: 3px solid white;
    font-size: 2.5em;
    padding: 10px;
    margin: 4px;
  }

  *:active {
    background-color: white;
    color: black;
  }
}

.hideNav {
  display: none;
}
