.extraInfo {
  .savingThrows {
    ul {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      list-style-type: none;
      margin: 0;
    }
  }
}
.bold {
  font-weight: bold;
}
