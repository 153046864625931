@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto Condensed";
}

body {
  background-color: bisque;
}

.App_main__1JcqF {
  top: 10vh;
  position: absolute;
  background-color: bisque;
  height: 80vh;
  width: 100vw;
  overflow: auto; }

.Header_navHeader__2WIML {
  height: 10vh;
  width: 100vw;
  position: fixed;
  top: 0;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1vh 2vh;
  color: white;
  font-size: 8vh; }
  .Header_navHeader__2WIML .Header_bars__1D2wY,
  .Header_navHeader__2WIML .Header_dnd__2w-l8 {
    cursor: pointer; }
  .Header_navHeader__2WIML .Header_bars__1D2wY:active,
  .Header_navHeader__2WIML .Header_dnd__2w-l8:active {
    color: darkgray; }

nav {
  background-color: rgba(0, 0, 0, 0.404);
  position: absolute;
  top: 10vh;
  left: 0;
  height: 90vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 1; }
  nav * {
    display: block;
    background-color: #00000096;
    color: white;
    text-decoration: none;
    border: 3px solid white;
    font-size: 2.5em;
    padding: 10px;
    margin: 4px; }
  nav *:active {
    background-color: white;
    color: black; }

.NavMenu_hideNav__2efLl {
  display: none; }


.StoryCard_storyCard__NxFC9 {
  box-shadow: 10px 10px 10px grey;
  width: 90vw;
  background-color: white;
  margin: 3vh auto; }
  .StoryCard_storyCard__NxFC9 h2 {
    font-size: 2.2em;
    padding: 20px;
    text-align: center; }
  .StoryCard_storyCard__NxFC9 p {
    font-size: 1.2em;
    padding: 0 20px 20px 20px; }

.Footer_storyBeatFooter__o5R0c {
  background-color: black;
  height: 10vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  overflow-x: auto;
  flex-wrap: nowrap;
  box-shadow: 0px -8px 10px grey; }

.FooterCard_footerCard__xOsrK {
  text-decoration: none;
  color: black;
  cursor: pointer; }
  .FooterCard_footerCard__xOsrK section {
    flex: 0 0 auto;
    background-color: white;
    min-width: 30vw;
    height: 8vh;
    border-radius: 15px;
    margin: 0 2vw;
    display: flex;
    align-items: center;
    justify-content: center; }
    .FooterCard_footerCard__xOsrK section h3 {
      text-align: center; }

.MonsterDetails_details__1GfX8 {
  border-bottom: 3px solid red; }

.MonsterCombatInfo_speeds__2kS9g {
  list-style-type: none;
  border-bottom: 3px solid red; }
  .MonsterCombatInfo_speeds__2kS9g li {
    font-size: 1.2em;
    padding: 0 20px; }

.MonsterStats_stats__12Vs0 ul {
  display: flex;
  list-style-type: none;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  border-bottom: 3px solid red;
  padding: 10px 0; }

.MonsterStats_bold__1fZoX {
  font-weight: bold; }

.MonsterExtraInfo_extraInfo__1RcgM .MonsterExtraInfo_savingThrows__N9BOR ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  margin: 0; }

.MonsterExtraInfo_bold__2xwVc {
  font-weight: bold; }

.MonsterCard_monsterCard__3Hqo6 {
  background-color: white;
  padding: 10px;
  box-shadow: 10px 10px 10px grey;
  width: 90vw;
  margin: 3vh auto;
  border-top: 3px solid red;
  border-bottom: 3px solid red; }
  .MonsterCard_monsterCard__3Hqo6 h2 {
    font-size: 2.2em;
    padding: 5px;
    text-align: center; }
  .MonsterCard_monsterCard__3Hqo6 p {
    font-size: 1.2em;
    padding: 5px 20px; }

.MonsterCard_stats__AYJ9h ul {
  display: flex;
  list-style-type: none;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  border-bottom: 3px solid red;
  padding: 10px 0; }

.MonsterCard_extraInfo__2wGEM .MonsterCard_savingThrows__2Qb7V ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  margin: 0; }

.MonsterCard_bold__BSh1E {
  font-weight: bold; }

.Footer_monsterCardFooter__2UcLp {
  background-color: black;
  height: 10vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  overflow-x: auto;
  flex-wrap: nowrap;
  box-shadow: 0px -8px 10px grey;
  display: flex;
  justify-content: center; }
  .Footer_monsterCardFooter__2UcLp input {
    width: 50%;
    height: 50%; }

