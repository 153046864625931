.monsterCardFooter {
  background-color: black;
  height: 10vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  overflow-x: auto;
  flex-wrap: nowrap;
  box-shadow: 0px -8px 10px grey;
  display: flex;
  justify-content: center;

  input {
    width: 50%;
    height: 50%;
  }
}
