.monsterCard {
  background-color: white;
  padding: 10px;
  box-shadow: 10px 10px 10px grey;
  width: 90vw;
  margin: 3vh auto;
  border-top: 3px solid red;
  border-bottom: 3px solid red;

  // .details {
  //   border-bottom: 3px solid red;
  // }

  h2 {
    font-size: 2.2em;
    padding: 5px;
    text-align: center;
  }

  p {
    font-size: 1.2em;
    padding: 5px 20px;
  }
}
// .speeds {
//   list-style-type: none;
//   border-bottom: 3px solid red;
//   li {
//     font-size: 1.2em;
//     padding: 0 20px;
//   }
// }

.stats {
  ul {
    display: flex;
    list-style-type: none;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    border-bottom: 3px solid red;
    padding: 10px 0;
  }
}

.extraInfo {
  .savingThrows {
    ul {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      list-style-type: none;
      margin: 0;
    }
  }
}

.bold {
  font-weight: bold;
}
