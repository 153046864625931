@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto Condensed";
}

body {
  background-color: bisque;
}
