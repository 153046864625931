.footerCard {
  text-decoration: none;
  color: black;
  cursor: pointer;

  section {
    flex: 0 0 auto;
    background-color: white;
    min-width: 30vw;
    height: 8vh;
    border-radius: 15px;
    margin: 0 2vw;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      text-align: center;
    }
  }
}
